.review-container{
    width:100%;
    display: flex;
    justify-content: center;
}
.review-wrapper{
    background: #1766C1;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
}
.review_title{
    width:62%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.review_title h1{
    width: 100%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding-top: 8px;
    font-size: 37px;
    line-height: 40px;

}
@media screen and (max-width:1300px) {
    .review_title h1{
        font-size: 20px;
    }
}
@media(max-width:1435px){

}
.review_title h3{
    width: 100%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding-top: 4px;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 7px;
}
.review_description{
    width:70%;
    font-size:18px;
    color:white;
    font-family: 'Poppins', sans-serif;
    padding-top: 30px;
}
.review_description h3{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.review_image{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-left: 4%;
}
.review_image_img{
    width: 94px;
}
.review_icon{
    width: 90px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:1300px) {
    .review_icon{
        width: 70px;
    }
}
.xp-content-review{
    font-family: 'Poppins', sans-serif;;
    color:black;
    -webkit-text-stroke: 2px white;
    position: relative;
    font-size: 39px;
    font-weight: bold;
    line-height: 40px;

}
@media screen and (max-width:1300px) {
    .xp-content-review{
        font-size: 24px;
    }
}
.xp-text{
    font-family: 'Poppins', sans-serif;
    color:#FF0000;
    -webkit-text-stroke: 2px white;
    padding-left:3px;
    position: relative;
    font-size: 39px;
    font-weight: bold;
    line-height: 40px;
}
@media screen and (max-width:1300px) {
    .xp-text{
        font-size: 26px;
    }
}
.review_icon_exp{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.review_icon_exp img{
    width: 50px;
}
@media screen and (max-width:1300px) {
    .review_icon_exp img{
        width: 40px;
    }
}
.review-edit-button{
    width:30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ex-student-list-container{
    width:100%;
    min-height: 300px;
    background: white;
    margin-top: -10px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    border-radius: 10px;
    display: inline-block;
    max-height: 94.7vh;
    overflow-y: scroll;
}
.ex-student-list-container::-webkit-scrollbar {
    width: 8px; 
    height: 10px; 
    border-radius: 8px;
  }
  .ex-student-list-container::-webkit-scrollbar-thumb {
    background: #fff;
    border: 1px solid #EAEAEA;
    width: 6px;
    border-radius: 8px;
  }
  .ex-student-list-container::-webkit-scrollbar-track { 
    background: #F8F8FB;
    box-shadow: inset -2px 3px 5px rgba(0,0,0,0.16);
    width: 8px;
    border-radius: 8px;
  }
.ex-student-list-title{
    text-align: center;
 
    font-size: 30px;
    font-weight: 600;
    padding-top: 10px;
}
.ex-student-list-table{
    width:100%;
    margin-top: 15px;
}
.ex-student-list-table .MuiSvgIcon-root{
    width: 17px;
    color: #ababab;
}
.ex-student-list-table .MuiTableCell-root{
    padding: 3px;
}
.ex-student-list-table .MuiTable-root{
    width: 98%;
    margin-left: 1%;
}
.student-list-ex-com-not{
    color:#F70606;
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;

}
.student-list-ex-com{
    color:green;
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;
}
.item-student-name-ex{
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;
    margin: 0;
}
.analystics{

    height: 100%;
    padding-left: 17px;
}

.analystics.experience-without-image{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 45px 0;
}

.analystics.experience-without-image > div{ 
    width: 48%;
    min-width: 470px;
}
@media(max-width:1230px){
    .analystics.experience-without-image > div{ 
        width: 100%;
        margin: 12px;
    }
}

.review-analyis{
    width: 100%;
    background: white;
    border-radius: 8px;
    display: flex;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    height: 140px;
}
@media(max-width:1435px){
    .review-analyis{
       flex-wrap: wrap;
       justify-content: center;
       height: fit-content;
    }
    div.counter-student-container{
        padding-bottom: 10px;
    }
   }
.analyis-header{
    width: 170px;
    display: flex;
    max-height: 139px;
    
}
.completion-rate{
    width:25%
}
.completion-rate p{
    font-family: 'Poppines', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #B7B7BC;
    position: relative;
    padding: 20px 20px 0 20px;
}

.completion-rate p:last-child{
    color: #212529;
    position: relative;
    padding: 7px 14px;
    font-family: 'Poppines', sans-serif;
    font-weight: bold;
    font-size: 18px;

}
.total-student{
    display: flex;
    padding-top: 25px;
    position: relative;
    flex-direction: row;
    align-items: baseline;
    max-width: 433px;
    margin: 0 auto;

}
.total-student p{
    margin:0;
    padding:0;
    text-align: center;
}
.total-student p:first-child{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size:18px;
    width: 30%;
    text-align: start;
}
.total-student p:last-child{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size:16px;
    color:#B7B7BC;
    position: relative;  
    width: 23%; 
    text-align: end;
}
.counter-student-container{
    width:100%;
    padding:0 10px;
}
.compelete-ex,.not-compeleted-ex,.view-ex{
    max-width: 450px;
    justify-content: center;
    padding:2px 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.complete-ex-text{
    width:30%;
    margin:0;
    padding:0;
    font-family: 'Poppins', sans-serif;
    font-size:14px;
    color:#495057;
    font-weight: 400;
}
.complete-ex-value{
    width:20%;
    margin:0;
    padding:0;
    font-family: 'Poppins', sans-serif;
    font-size:14px;
    color:#495057;
    font-weight: 600;
    text-align: end;
}
.complete-ex-liner-container{
    width: 45%;
    padding-left: 4px;
   
}
.compelte-ex-liner-chart{
   
    height: 10px;
    border-radius: 5px;
    background: #34C38F;
    transition: all 0.3s ease-in-out;
}
.not-compelte-ex-liner-chart{
   
    height:10px;
    border-radius: 5px;
    background: #F46A6A;
    transition: all 0.3s ease-in-out;
}
.view-ex-liner-chart{
   
    height: 10px;
    border-radius: 5px;
    background: #556EE6;
}

.photo-submission-container{
    width: 100%;
    background: white;
    max-height: 95vh;
    margin-top: 18px;
    border-radius: 8px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    padding: 0 10px;
    overflow-x: hidden;
    overflow-y: scroll;

   
}
.photo-submission-container::-webkit-scrollbar {
    width: 18px; 
    height: 10px; 
    border-radius: 8px;
  }
  .photo-submission-container::-webkit-scrollbar-thumb {
    background: #fff;
    border: 1px solid #EAEAEA;
    width: 16px;
    border-radius: 8px;
  }
  .photo-submission-container::-webkit-scrollbar-track { 
    background: #F8F8FB;
    box-shadow: inset -2px 3px 5px rgba(0,0,0,0.16);
    width: 18px;
    border-radius: 8px;
  }
.photo-submission-title{
    text-align: center;
    font-family: 'Poppines', sans-serif;
    font-weight: 500;
    font-size: 44px;
    padding-top: 6px;
}
@media screen and (max-width:1300px) {
    .photo-submission-title{
        font-size: 30px;
    }
}
.photo_container{
    width: 100%;
    padding: 8px 0px;
    min-height: 257px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
}
.photo-item-container{
    width: 35%;
    max-width: 225px;
    min-width: 210px;
    margin-bottom: 5px;
    max-height: 300px;
    position: relative;
    margin-right: 5px;

}
.photo-item-container > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    background-color: black;

    cursor: pointer;
   
}
.photo-item-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(0,0,0,0.3);
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
}
.photo-item-control{
    width:100%;
    position: absolute;
    z-index: 10;
    display: flex;
    top: 0;
    justify-content: space-between;

}
.photo-item-profile{
    display: flex;
    position: absolute;
    bottom: 0;
    padding-top: 35px;
    height: 116px;
}
.photo-item-profile p{
    color: white;
    text-align: left;
    font-family: "Poppins" , 'sans-serif';
    font-weight: 900;
    text-transform: capitalize;
    font-size: 16px;
    padding-left: 76px;
    padding-top: 24px;
    overflow: hidden;
    max-width: 208px;
    white-space: nowrap;
    text-overflow: ellipsis;
   
}
