.student-xp{
   
    height: 120px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    border-radius: 5px;
   display: flex;
   align-items: center;
   padding-right: 20px;
   margin-bottom: 10px;

}
.shiled{
    padding-left: 20px;
    display: flex;
    align-items: center;
    position: relative;
}
.shiled h3{
    position: absolute;
    top: 15px;
    left: 29px;
    font-size: 15px;
    text-align: center;
    color: white;
    font-family: 'Poppins';
    width: 62px;
}
.shiled h3 p{
    padding:0;
    margin:0;
}
.xp-content{
    width:100%;
}
.xp-content-title{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}
@media screen and (max-width:1300px) {
    .xp-content-title{
        font-size: 28px;
    }
}

.xp-progress-container{
    background:#E3E3E3;
    height: 31px;
    width:100%;
    border-radius: 50px;
}
.progress-xp{
    width:50%;
    background: #4ED486;
    height: 31px;
    border-radius: 50px;
}
@media (max-width: 1720px) {
    .st-info-game-status{
        flex-wrap: wrap;
    }
    .st-info-leader-board{
        padding: 50px 0;
    }
}
@media (max-width: 1535px) {
    .st-info-leader-board{
        padding:  0;
    }
    .st-info-circlechart-container{
        width: 75vw !important;
    }
      .st-info-team-data, .st-info-leader-board{
        margin: 50px 0;
        width: 34% !important;
    }
    .st-info-team-content{
        margin: 50px 0;
        width: 25% !important;
    }
}


