
.style {
    font-size: 12px;
    display: flex;
    margin-left: 10px;
    margin-top: 0;
    align-items: center;
    background: #00C88B;
    border-radius: 5px;
    box-shadow: 0px 0px 0px;
    border-color:#00C88B;
    border: 0;
    width: 90px;
    height: 30px;
    color:white;
    padding: 15px;
    display: flex;
    justify-content: center;
  }

.backdrop  {
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    cursor: pointer;
    backdrop-filter: blur(15px);
}
.slideBoxProfile {
    display: flex;

    position: absolute;
    justify-content:space-around;
    bottom: -10px;
    width: 95%;
    height:100px;
    align-items: center;
}
.imageSlider  {
    width: 54%;
    height:  80% ;
    display:  block ;
    position:  fixed ;
    background:  none ;
     align-items:  center ;
    z-index: 20000;
    left: 23% ;
    top:  10% ;
    background:  rgba(0,0,0,0.8) ;
    backdrop-filter:  blur(15px) ;
}
.imageSlide  {
   width:  100% ;
   height:  calc(100% - 40px) ;
    border-radius: 0;
   margin:  20px auto ;
   display:  block ;
   text-align:  center ;
    align-items:  center ;
   padding: 20px;
   position:  relative ;
   z-index: 1
}
.slideBoxProfileContent  {
  width:  calc(100% - 90px) ;
  display:  flex ;
   align-items:  center ;
  flex-direction:  column ;
  transform:  scale(0.2) ;
  padding-left:  30% ;
}
.imageStyle  {
    width:  100% ;
    height:  92% ;
    margin:  0 auto ;
    object-fit:  contain ;
    position:  relative ;
}
.slideButtons  {
    width:  calc(100% - 30px) ;
    position: absolute ;
    padding: 15px;
    top: -10px;
    display:  flex ;
    flex-flow:  row ;
    justify-content:  space-between 
}
.nextBtnStyle  {
    width: 55px;
    height: 55px;
    position:  absolute ;
    right: 10px;
    top:  48% ;
    background:  black ;
     border-radius:  5px ;
    z-index: 25000;
    border: 2px solid #fff 
}
.previousBtnStyle  {
    width: 55px;
    height: 55px;
    position:  absolute ;
    left: 10px;
    top:  46% ;
    background:  black ;
     border-radius:  5px ;
    border: 2px solid #fff ;
    z-index: 25000
}