.awarder-container .MuiTableRow-head .MuiTableCell-head {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.awarder-container .MuiTableRow-root .MuiTableCell-body {
    font-size: 20px;
    font-family: "Rajdhani";
    font-weight: 400;
    text-align: center;
}

.awarder-container .MuiTableCell-root {
    padding: 3px;
}

.awarded-title {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 500;
    color: #495057;
}

@media screen and (max-width:1300px) {
    .awarded-title {
        font-size: 20px;
    }
}

.awarded-table {

    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
    padding: 9px;
}

.xpContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

}

.xpPanle {
    background: #6B6875;
    width: 309px;
    height: 240px;
    border-radius: 25px;
    position: absolute;
    right: 100px;

    z-index: 1000;
}

.xpPanel_header {
    color: white;
    width: 100%;
    text-align: left;
    padding-top: 21px;
    padding-left: 30px;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding-bottom: 2px;
}

.xp_description {
    text-align: left;
}

.xpDescription {
    width: 235px;
    margin-top: 7px;
    margin-left: 6px;
    padding: 11px 12px;
    border-radius: 50px;
    border: none;
    color: white;
    background: #707070;
    font-size: 11px;


}

.xpDescription:focus {
    border: none;
    outline: none;
}

.xpDescription::placeholder {
    color: white;
}

.addxpbutton {
    background: #F0C26A;
    color: white;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50px;
    border: none;
    font-size: 34px;
    line-height: 40px;
    bottom: 46px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    right: 19px;
    padding-bottom: 0;

}

.xpDate {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 11px 27px;


}

.awarded-edit-button {
    display: flex;
    height: 36px;
    border-radius: 4px;
    width: 70px;
    background-color: #34C38F;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition-duration: 300ms;
    border: none;
}
.awarded-edit-button:hover {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
}
.awarded-edit-button-text {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 10px;
    color: #fff;
    margin: 0;
    padding-right: 5px;
}

.awarded-edit-button-icon {
    width: 18px;
    height: 18px;
    background-color: #fff;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
}