@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

.student-table-container .MuiTableCell-head {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.student-table-container .MuiTableCell-body {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  text-align: center;
}

@media (max-width: 1300px) {
  .student-table-container .MuiTableCell-head {
    font-size: 12px !important;
  }

  .student-table-container .MuiTableCell-body {
    font-size: 12px !important;
  }
}

.student-table-container .MuiTableCell-root {
  padding: 8px !important;
  width: 20px;
}

.student-table-container .MuiTableCell-root>div {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.student-table-container .MuiTableCell-root>div img {
  margin-left: 10px;
}

.organization-table-container .MuiTableCell-root>div img {
  margin-left: 10px;
    top: 3px;
    position: relative;
}
body .react-multiple-carousel__arrow--left:before{
  content: "<";
  font-family: inherit;
}
body .react-multiple-carousel__arrow--right:before {
  content: ">";
  font-family: inherit;
}


.shop-table-container .MuiTableCell-root.MuiTableCell-head {
  font-weight: 400 !important;
}

.sortIcon {
  margin-left: 10px;
}

body .MuiStack-root {
  overflow: hidden;
  padding: 0;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
  height: 27px;
  padding: 10px;
  margin: 0;
  border: none;
}
body .login_container .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary{
  height: 40px;
}

body .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 0;
  border: none;
}

body .MuiOutlinedInput-notchedOutline {
  border: none;
}

body .css-1aoio6i-MuiFormControl-root-MuiTextField-root {
  padding: 5px 10px;
}

.MuiAutocomplete-popper {
  z-index: 60000000000000 !important;
}

h5 {
  font-size: 1.25rem;
  font-weight: 600;
}

.h2,
h2 {
  font-size: 2rem;
  font-weight: 600;
}

p {
  margin-top: 0;
  font-size: 14px;
}

body .css-1lambp5 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 4px 0 6px;
}

body .css-1uvydh2 {
  padding: 0 0 0 14px;
}

body .css-d4vl8e {
  height: 100%;
  padding: 0 !important;
}

body .MuiPickersPopper-root.MuiPopper-root {
  z-index: 99999;
}

#clockdiv{ 
  display: flex;
  width: 100%;
}
#clockdiv > div{
   display: flex;
   align-items: center;
   justify-content: center;
   align-content: center;
   text-align: center;
}
#clockdiv div > span{
   color: #fff;
   font-size: 16px;
   font-family: "Rajdhani";
   font-weight: bold;
   line-height: 34px;
}
@media screen and (max-width:1300px) {
   #clockdiv div > span{
       font-size: 20px;
   }
}
.smalltext{
   color: #fff;
   font-size: 16px;
   font-family: "Rajdhani";
   font-weight: bold;
   line-height: 34px;
   padding: 0 5px;
}
@media screen and (max-width:1300px) {
   .counter-responsive .smalltext{
       font-size: 0;
   }
   .counter-responsive .smalltext:first-letter {
       font-size: 16px;
     }
}

button{
  cursor: pointer;
}

body .MuiTablePagination-root  .MuiTablePagination-toolbar{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
body .MuiTablePagination-root  .MuiTablePagination-spacer{
  flex: 0;
}

.MuiAutocomplete-root {
  border: 1px solid #ced4da;
  border-radius: 3px;
  height: 30px;
}

.MuiOutlinedInput-root .MuiAutocomplete-input{
  padding: 0.5px 4px 0 6px !important;
}


.content.close{
  padding-left: 60px;

}


.sidebar.close{
    min-width: 60px;
    width: 60px;
    position: fixed;
 
}


.sidebar.open div:not(:last-child){

}

.sidebar.close div p , .sidebar.close div h4 {
  opacity: 0;
  width: 0;
  height: 20px;

}
.responsive-logo{
  display: none;
}

.sidebar.close .responsive-logo{
  display: flex;
  width: 100%;
  top: 4px;
  max-width: 33px;
  position: relative;
}

.sidebar.close .desktop-logo{
  display: none;
}
.sidebar.close div svg {
  opacity: 1;
  transition-delay: 0ms;
  margin: 0;
  width: 20px;
  height: 20px;

}
.sidebar.close > div{
  padding: 14px;
}
.sidebar.close .collapse-icon{
  display: none;
}
.sidebar.open .collapse-icon.close{
  display: none;
}
.sidebar.close .collapse-icon.close {
  opacity: 1;
  transition-delay: 0ms;
  display: flex;
  margin: 0;
  width: 25px;
  height: 25px;
  right: -35px;
  position: absolute;
  top: 7px;
}

.sidebar.close .collapse-icon path:not(:nth-child(1)){

    stroke: rgb(0, 0, 0);
}