
.spinner-img{
  width: 40;
  height: 40;
  position: absolute;
  top: calc(50% - 12px);
  left: 50%;
  animation: rotate 1s linear infinite;
  transform: translateZ(0);

}

@keyframes rotate {
     from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
}