.login_container{

    background-image: url("../../../../assets/image/login-back.png");

}
.login_wrapper{


}
.login_header{

}
.login-content{

}
.username{
    width:100%;
}
.username label,.password label{
    display: block;
    font-family: "PoppinsMedium";
    color: #495057;
    font-size: 13px;
}
.username input,.password input{
    width:100%;
    margin-top: 9px;
    padding:8px;
    padding-left: 12px;
    border-radius: 4px;
    color:#495057;
    font-size: 13px;
    border:1px solid #CED4DA;
    font-family: "PoppinsRegular";
}
.password{
    width:100%;
    margin-top: 14px;
    position: relative;
}
.eye-icon{
    position: absolute;
    bottom: -5px;
    right: 0;
}
.loginbutton{

  
}
.loginbutton button{

}
.logo{
    max-width: 700px;
    position: relative;

    
}
.headerimage{
    width: 39%;
    right: 10px;
    position: relative;
}
.login-content-title{

}
.forgot-password{

}
.verification-resend-code{

}
.forgot-password-button{
    font-size: 12px;
    font-family: "Rajdhani";
    color: #34269E;
    line-height: 15px;
    text-align: right;
    font-weight: bold;
    width: 100%;
    border: none;
    background: none;
    padding-top: 15px;
    padding-bottom: 10px;
}